<template>
  <div id="app">
    <input type="text" v-model="pwd" placeholder="请输入密码" style="height: 40px;font-size: 18px;" v-if="wspwd != pwd">

    <!-- 预测金额表 -->
    <!-- <div class="a" v-if="wspwd === pwd"
      style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;border:1px solid #000;margin-bottom: 10px;">

      <div style="background-color: #000;color: white;">预测</div>
      <div>1</div>
      <div>2</div>
      <div>3</div>
      <div>4</div>
      <div>5</div>
      <div>6</div>
      <div style="font-size: 12px;">总落子</div>
      <div style="background-color: #000;color: white;">金额</div>
      <div :style="{ color: a1 * 1 > 0 ? 'blue' : 'red' }">{{ a1 }}</div>
      <div :style="{ color: a2 * 1 > 0 ? 'blue' : 'red' }">{{ a2 }}</div>
      <div :style="{ color: a3 * 1 > 0 ? 'blue' : 'red' }">{{ a3 }}</div>
      <div :style="{ color: a4 * 1 > 0 ? 'blue' : 'red' }">{{ a4 }}</div>
      <div :style="{ color: a5 * 1 > 0 ? 'blue' : 'red' }">{{ a5 }}</div>
      <div :style="{ color: a6 * 1 > 0 ? 'blue' : 'red' }">{{ a6 }}</div>
      <div :style="{ color: a7 * 1 > 0 ? 'blue' : 'red' }">{{ a7 }}</div>
    </div> -->
    <div class="yc-table" v-if="wspwd === pwd">
      <div style="background-color: #000;color: white;">预测</div>
      <div style="background-color: #000;color: white;">金额</div>
      <div>1</div>
      <div :style="{ color: a1 * 1 > 0 ? 'blue' : 'red' }">{{ a1 }}</div>
      <div>2</div>
      <div :style="{ color: a2 * 1 > 0 ? 'blue' : 'red' }">{{ a2 }}</div>
      <div>3</div>
      <div :style="{ color: a3 * 1 > 0 ? 'blue' : 'red' }">{{ a3 }}</div>
      <div>4</div>
      <div :style="{ color: a4 * 1 > 0 ? 'blue' : 'red' }">{{ a4 }}</div>
      <div>5</div>
      <div :style="{ color: a5 * 1 > 0 ? 'blue' : 'red' }">{{ a5 }}</div>
      <div>6</div>
      <div :style="{ color: a6 * 1 > 0 ? 'blue' : 'red' }">{{ a6 }}</div>
      <div>总落子</div>
      <div :style="{ color: a7 * 1 > 0 ? 'blue' : 'red' }">{{ a7 }}</div>
    </div>
    <div class="b" style="border-top: 1px solid #000;" v-if="wspwd === pwd">
      <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;">

        <div>群号</div>
        <div>应付</div>
        <div>落子</div>
        <div>小费</div>
        <div>输赢</div>
      </div>
      <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;" v-for="item, index in c" :key="index"
        class="b2-content">
        <div>{{ item.desno }}号</div>
        <div :style="{ color: item.yf * 1 > 0 ? 'blue' : 'red' }">{{ item.yf }}</div>
        <div>{{ item.lz }}</div>
        <div>{{ item.xf }}</div>
        <div :style="{ color: item.sy * 1 > 0 ? 'blue' : 'red' }">{{ item.sy }}</div>
      </div>
    </div>

    <div class="b" style="border-top: 1px solid #000;margin-top:20px"
      v-if="wspwd === pwd && Object.keys(hddzFilterData).length > 0">
      <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;">

        <div>落子</div>
        <div>群号</div>
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
        <div>6</div>
      </div>
      <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;"
        v-for="item, index in hddzFilterData" :key="index" class="b2-content">
        <div>{{ item['luozi'] }}</div>
        <div>{{ item['wjx.desno'] }}号</div>
        <div :style="{ color: item['yuce'][0] * 1 > 0 ? 'blue' : 'red' }">{{ item['yuce'][0] }}</div>
        <div :style="{ color: item['yuce'][1] * 1 > 0 ? 'blue' : 'red' }">{{ item['yuce'][1] }}</div>
        <div :style="{ color: item['yuce'][2] * 1 > 0 ? 'blue' : 'red' }">{{ item['yuce'][2] }}</div>
        <div :style="{ color: item['yuce'][3] * 1 > 0 ? 'blue' : 'red' }">{{ item['yuce'][3] }}</div>
        <div :style="{ color: item['yuce'][4] * 1 > 0 ? 'blue' : 'red' }">{{ item['yuce'][4] }}</div>
        <div :style="{ color: item['yuce'][5] * 1 > 0 ? 'blue' : 'red' }">{{ item['yuce'][5] }}</div>

      </div>
    </div>
    <!-- {{hddzFilterData}} -->
    <!-- <div @click="refresh" style="border:1px solid red;padding: 15px;">刷新</div> -->
  </div>
</template>

<script>

export default {
  name: 'App',

  data: () => {
    return {
      a1: 0,
      a2: 0,
      a3: 0,
      a4: 0,
      a5: 0,
      a6: 0,
      a7: 0,
      str: '{"desno":"6","win":0.00,"luozi":10.762,"yuce":"-2.14@-0.63@-0.63@0.63@0.63@0.63"},{"desno":"7","win":0.00,"luozi":10.762,"yuce":"-2.14@-0.63@-0.63@0.63@0.63@0.63"},{"desno":"8","win":0.00,"luozi":10.762,"yuce":"-2.14@-0.63@-0.63@0.63@0.63@0.63"}',
      b: {},
      c: [],
      ws: {},
      inter: {},
      wspwd: undefined,
      pwd: "123",
      ver: undefined,
      hasPwd: true,
      hddzFilterData: {},
    }
  },
  methods: {
    refresh: function (ele) {
      // let obj = JSON.parse('[' + this.str + ']')

      this.b[ele.desno + '-'] = ele
      let k = Object.keys(this.b)
      this.c = []
      k.forEach(ele => {
        this.c.push(this.b[ele])
      })
      this.c.sort((p1, p2) => {
        return p1.desno * 1 - p2.desno * 1
        // return p2.fori * 1 - p1.fori * 1
      })
    }
  },
  mounted() {
    const hash = location.hash.substring(1); // 去掉第一个 #
    const match = hash.match(/ver=([^&]+)/);
    const comet_url = "ws://121.41.55.141:3010"; // WebSocket URL

    if (!match) {
      console.error("未找到版本号");
      return;
    }

    this.ver = match[1];
    this.ws = new WebSocket(comet_url);

    this.ws.onopen = () => {
      // 心跳包
      this.inter = window.setInterval(this.sendHeartbeat, 1000);
    };

    this.ws.onmessage = (res) => {
      // 处理消息的逻辑
      this.handleMessage(res);
    };

    this.ws.onclose = (res) => {
      console.log("WebSocket连接关闭:", res);
      clearInterval(this.inter); // 清除心跳包定时器
      this.reconnect(); // 尝试重连
    };

    this.ws.onerror = (err) => {
      console.error("WebSocket发生错误:", err);
      clearInterval(this.inter); // 清除心跳包定时器
    };

    // 发送心跳包的函数
    this.sendHeartbeat = () => {
      if (this.ws.readyState === WebSocket.OPEN) {
        try {
          this.ws.send("line"); // 发送心跳包
        } catch (error) {
          console.error("发送心跳包时出错:", error);
        }
      }
    };

    // 处理消息的函数
    this.handleMessage = (res) => {
      try {
        if (res && res.data) {

          const obj = JSON.parse(res.data); // 解析 JSON
          console.log(obj);

          // 确保只处理有效的 JSON 消息
          if (res.data === "line") {
            return; // 跳过心跳包消息
          }

          if (obj['ver'] == this.ver || obj['wjx_ver'] == this.ver) {


            if (obj['wjx.desno']) {
              console.log(obj, this.ver);
              obj['yuce'] = obj['yuce'].split('@');
              this.hddzFilterData[obj['wjx.desno']] = obj;
              return;
            }
            // 合并赋值
            for (let i = 1; i <= 7; i++) {
              if (obj[`a${i}`]) {
                this[`a${i}`] = obj[`a${i}`];
              }
            }

            if (obj['fori']) this.refresh(obj);
            if (obj['pwd']) this.wspwd = obj['pwd'];
          }
        }
      } catch (error) {
        console.error("处理消息时出错:", error);
      }
    };

    // 自动重连的函数
    this.reconnect = () => {
      console.log("尝试重连 WebSocket...");
      setTimeout(() => {
        this.ws = new WebSocket(comet_url); // 创建新的 WebSocket 连接

        this.ws.onopen = () => {
          console.log("WebSocket连接成功");
          this.inter = window.setInterval(this.sendHeartbeat, 1000); // 重新启动心跳包
        };

        this.ws.onmessage = (res) => {
          this.handleMessage(res); // 调用处理消息的函数
        };

        this.ws.onclose = (res) => {
          console.log("WebSocket连接关闭:", res);
          clearInterval(this.inter); // 清除心跳包定时器
          this.reconnect(); // 尝试重连
        };

        this.ws.onerror = (err) => {
          console.error("WebSocket发生错误:", err);
          clearInterval(this.inter); // 清除心跳包定时器
        };
      }, 3000); // 每3秒重连一次
    };


  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;

}

.a>div {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  display: flex;
  font-size: 14px;
  align-items: center;
  align-content: center;
  justify-content: center;

}

.b>div {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  display: flex;
  font-size: 14px;
  align-items: center;
  align-content: center;
  justify-content: center;

}

.b2-content {
  font-weight: bold;
}

.b>div>div {
  border-right: 1px solid #000;
}

.yc-table {
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 50%;
  border-left: 1px solid #000;
  margin-bottom: 15px;


}

.yc-table>div {
  font-weight: bold;
  padding: 5px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  font-size: 18px;
}
</style>
